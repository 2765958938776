import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

import { useDevice } from '@/helpers/deviceContext';
import { isBannerBranding } from '@/helpers/isBannerBranding';
import { usePlatform } from '@/helpers/platformContext';
import { SlugEnum } from '@/helpers/slugEnums';

import Banner from '@/components/Banner';

import styles from '@/styles/components/Topline/ToplineTransparent.module.scss';

import ToplineDesktopTabs from './ToplineDesktopTabs';
import { useToplineReducer } from './toplineContext';

const ToplineTransparent: React.FC = () => {
  const [{ menuVisible, searchVisible }, dispatch] = useToplineReducer();
  const { logo_url, logo_white_url, title, banners } = usePlatform();
  const router = useRouter();
  const { isDesktop, isMobile } = useDevice();
  const logoUrl =
    (menuVisible || searchVisible ? logo_url : logo_white_url) ||
    'https://static.tildacdn.com/tild3563-6535-4065-a162-373933396564/1mi-2021-email-opaci.png';
  const ref = useRef<HTMLAnchorElement>(null);
  const onLogoClickCapture = () => {
    if (ref.current?.href) {
      ref.current.href = menuVisible ? '#' : '/';
      if (menuVisible) {
        dispatch({ type: 'SET_MENU_VISIBLE', payload: false });
      }
    }
  };
  return (
    <div
      data-testid="ToplineTransparent_top"
      className={classNames(styles.top, menuVisible && styles.topVisible, {
        [styles['__no-border']]: router.route === '/' && isMobile,
        [styles.paddingMobile]: isMobile,
        [styles.topVisible]: searchVisible,
      })}
    >
      <div className="inline-flex items-center gap-[24px]">
        <button
          data-testid="header_button_menu"
          type="button"
          className={classNames(styles.menu, {
            [styles.__active]: menuVisible || searchVisible,
            [styles.__close]: menuVisible,
          })}
          onClick={() => {
            dispatch({ type: 'SET_MENU_VISIBLE', payload: !menuVisible });
          }}
        />
        <a
          href="/"
          ref={ref}
          className={styles.logo}
          onClickCapture={onLogoClickCapture}
        >
          <img src={logoUrl} alt={title} />
        </a>
      </div>
      {isDesktop && <ToplineDesktopTabs isWhite />}
      {isBannerBranding(banners) && !menuVisible && !searchVisible && (
        <Banner slug={SlugEnum.all_header_inner} className="mr-2" />
      )}

      {(!isBannerBranding(banners) ||
        !isMobile ||
        menuVisible ||
        searchVisible) && (
        <button
          data-testid="header_button_search"
          type="button"
          className={classNames(styles.search, {
            [styles.__active]: menuVisible || searchVisible,
            [styles.__close]: searchVisible,
          })}
          onClick={() => {
            dispatch({ type: 'SET_SEARCH_VISIBLE', payload: !searchVisible });
          }}
        />
      )}
    </div>
  );
};

export default ToplineTransparent;
